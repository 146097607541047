<template>
	<section class="content-main">

		<el-form :model="form" label-width="140px">
			<el-form-item label="接受合作方案">
				<el-radio-group v-model="form.resource">
					<el-radio label="开启"></el-radio>
					<el-radio label="系统关闭"></el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="合作彩种">
				<el-checkbox-group v-model="form.type">
					<el-checkbox label="竞彩" name="type"></el-checkbox>
					<el-checkbox label="足彩" name="type"></el-checkbox>
					<el-checkbox label="体彩数字" name="type"></el-checkbox>
					<el-checkbox label="体彩高频" name="type"></el-checkbox>
					<el-checkbox label="福彩数字" name="type"></el-checkbox>
					<el-checkbox label="福彩高频" name="type"></el-checkbox>
					<el-checkbox label="北单" name="type"></el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="合作费率">
				千分之
				<el-input type="number" v-model="form.job_num" class="short-width"></el-input>
			</el-form-item>
			<el-form-item label="合作店铺">
				<div>
					<div>
						<el-input type="number" v-model="form.job_num" class="short-width"></el-input>
						<el-button type="primary" @click.native="saveSubmit">添加店铺</el-button>
					</div>
				</div>
				<el-table :data="listData" highlight-current-row style="width: 100%;">
					<el-table-column prop="create_time" label="合作出票店铺用户名" sortable width="200">
					</el-table-column>
					<el-table-column prop="name" label="合作出票店铺名称" width="200">
					</el-table-column>
					<el-table-column prop="remark" label="合作费率(千分比）">
					</el-table-column>
					<el-table-column label="操作" width="100" align="center">
						<template slot-scope="scope">
							<template>
								<el-button type="danger" plain size="small" @click="deleteRole(scope.row.id)" v-if="scope.row.status!='0'" icon="el-icon-delete" title="删除">删除</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>
			</el-form-item>
			<el-form-item label="">
				<el-button type="primary" @click.native="saveSubmit" :loading="Loading" icon="el-icon-circle-check">保存修改</el-button>
			</el-form-item>
		</el-form>
	</section>
</template>

<script>
	import {
		getStoreInfo,
		updateStoreInfo
	} from '../../api/api';
	export default {
		data() {
			return {
				//编辑ID
				aID: this.$route.query.id,					
				form: {}
			}
		},
		methods: {
			//取消返回
			goBack() {
				this.$router.go(-1);
			},

			//获取信息
			getInfo() {
				let para = {
					store_id: this.aID
				};
				getStoreInfo(para).then((res) => {
					this.form = Object.assign({}, res.data);
				});
			},
			//编辑保存
			saveSubmit: function() {
				//判断是否有不存在的数组ID
				let a = this.roleOption.map(i => i.id),
					b = this.form.role_id
				for(var i of b) {
					if(a.indexOf(i) < 0) {
						b.splice(b.indexOf(i), 1)
					}
				}
			},
		},
		mounted() {
			this.getInfo()
		}
	}
</script>
<style scoped>
	.interval {
		width: 100%;
		height: 12px;
		overflow: hidden;
		border-bottom: 1px solid #dddddd;
		margin-bottom: 12px
	}
</style>